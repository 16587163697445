@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Playfair+Display:wght@700&display=swap');

:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --accent-color: #e74c3c;
  --text-color: #333;
  --bg-color: #f9f9f9;
}

#hero {
  scroll-margin-top: calc(var(--navbar-height) + 20px);
  padding-top: calc(var(--navbar-height) + 20px);
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 60px 20px;
  background: #000; /* Dark background for cyberpunk feel */
  color: #fff;
  font-family: 'Poppins', sans-serif;
  animation: fadeIn 1.2s ease-in-out both;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto 40px;
  padding: 0 1rem;
}

.hero-title {
  font-size: 2.5rem;
  color: #00ff00;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: 1.2rem;
  color: #cccccc;
}

.hero-svg {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 1.1rem;
  line-height: 1.5;
  color: #bbbbbb;
  margin-bottom: 1rem;
}

/* Event Highlights */
.event-highlights {
  margin-top: 1rem;
}
.section-title {
  font-size: 1.5rem;
  color: #00ff00;
  margin-bottom: 0.5rem;
}
.event-list {
  list-style: disc;
  margin-left: 1.5rem;
  text-align: left;
  color: #dddddd;
}
.event-list li {
  margin-bottom: 0.3rem;
}

/* Features container: Use flexbox so items remain in a row, with horizontal scrolling if necessary */
.features-container {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  justify-content: center;
  padding: 0 1rem;
}

/* Optional: hide scrollbar on WebKit browsers */
.features-container::-webkit-scrollbar {
  display: none;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to   { opacity: 1; transform: translateY(0); }
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .hero-title {
    font-size: 2rem;
  }
  .hero-description {
    font-size: 1rem;
  }
}
