/* Container for the matrix section */
.matrix-section {
  padding: 2rem 1rem;
  background: var(--clr-primary); /* Use primary color */
}

/* Grid container */
.matrix-grid {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Title styling */
.matrix-title {
  color: var(--clr-green); /* Using brand green */
  font-family: 'Courier New', monospace;
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 0 0 15px var(--matrix-green);
  border-bottom: 3px solid var(--clr-blue); /* Adds an orange line under the title */
  padding-bottom: 0.5rem; /* Adjust spacing as needed */
}

/* Address cards grid: 2 columns */
.address-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

/* Address card styling */
.address-card {
  background: var(--clr-secondary); /* Use secondary color */
  border: 1px solid var(--clr-green);
  border-radius: 8px;
  padding: 1rem;
  text-align: left;
  box-shadow: 0 0 8px rgba(37,206,143,0.2); /* subtle green glow */
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.address-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 25px rgba(37,206,143,0.3);
}

.address-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(37,206,143,0.1), transparent);
  transition: 0.5s;
}

.address-card:hover::before {
  left: 100%;
}

.address-header {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 0.5rem;
}

.contract-name {
  font-weight: 600;
  color: var(--clr-green);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.contract-address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: monospace;
  color: var(--clr-neutral);
  opacity: 0.9;
  margin-bottom: 0.5rem;
}

.copy-button {
  background: none;
  border: none;
  color: var(--clr-green);
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
}

.copy-button:hover {
  text-decoration: underline;
}

.explorer-links {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
}

.explorer-link {
  color: var(--clr-green);
  text-decoration: none;
}

.explorer-link:hover {
  text-decoration: underline;
}

/* External action buttons section */
.matrix-links {
  text-align: center;
  margin-top: 1.5rem;
}

.matrix-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-green);
  background: transparent;
  border: 2px solid var(--clr-green);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s, color 0.3s, box-shadow 0.3s;
  font-size: 0.9rem; /* Compact size */
}

.matrix-button:hover {
  background: var(--clr-green);
  color: var(--clr-black);
  box-shadow: 0 0 8px var(--clr-green);
}

.matrix-button.disabled {
  background: #333;
  border-color: #555;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Icon styling for buttons */
.btn-icon {
  margin-right: 0.5rem;
  font-size: 1rem;
}

/* Responsive: On mobile, stack address cards */
@media (max-width: 600px) {
  .address-grid {
    grid-template-columns: 1fr;
  }
}
