/* Navbar.css */

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-height);
  background: var(--clr-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Brand Section with Gradient Title */
.navbar__brand {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.navbar__logo {
  width: 40px;
  height: auto;
}
.navbar__brand h1 {
  font-size: clamp(1.25rem, 2vw, 1.5rem);
  background: var(--header-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}

/* Navigation Links */
.navbar__nav {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 1.5rem;
  transform: translateX(-40px); /* adjust this value as needed */
}
.nav__link {
  color: var(--clr-white);
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.2s ease;
}
.nav__link:hover {
  color: var(--clr-blue);
}

/* Mobile Menu Toggle */
.navbar__toggle {
  display: none;
  background: none;
  border: none;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}
.navbar__toggle .bar {
  width: 25px;
  height: 3px;
  background-color: var(--clr-white);
  transition: all 0.3s ease;
}

/* Account Section (Desktop always visible) */
.navbar__account {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.account__info {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.account__balance {
  font-size: 0.9rem;
  font-weight: 600;
}
.account__address {
  font-size: 0.8rem;
  color: var(--clr-white);
  text-decoration: none;
}
.disconnect-button,
.connect-button {
  background: var(--clr-blue);
  color: var(--clr-white);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.disconnect-button:hover,
.connect-button:hover {
  background: var(--clr-green);
}
.account__blockies {
  border-radius: 50%;
}


/* Mobile Styles */
@media (max-width: 768px) {
  .navbar__toggle {
    display: flex;
  }
  /* Mobile Navigation Panel */
  .navbar__nav {
    position: fixed;
    top: var(--navbar-height);
    left: 0;
    width: 80%;
    height: calc(100vh - var(--navbar-height));
    background: var(--clr-primary);
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    transform: translateX(-100%); /* Hidden by default */
    transition: transform 0.3s ease;
    z-index: 1001; /* Make sure nav is on top */
  }
  .navbar__nav.active {
    transform: translateX(0);
  }
  /* The overlay covers the rest of the screen */
  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Below the nav panel */
  }
  /* Keep the account section visible in header */
  .navbar__account {
    display: flex;
  }
}
