.footer {
  background: linear-gradient(to bottom, var(--clr-primary), var(--clr-secondary));
  color: var(--clr-neutral);
  padding: 2.5rem 1.5rem;
  font-family: 'DM Sans', sans-serif;
}

.description {
  color: rgba(255,255,255,0.9);
  font-size: 1.1rem;
}

.link {
  font-weight: 500;
  padding: 0.5rem;
  border-radius: 8px;
  background: rgba(255,255,255,0.1);
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto 2rem;
}

.section {
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  backdrop-filter: blur(4px);
}

.heading {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.heading::after {
  content: '';
  display: block;
  width: 50px;
  height: 2px;
  background-color: var(--clr-blue);
  position: absolute;
  bottom: 0;
  left: 0;
}

.description {
  font-size: 1rem;
  line-height: 1.6;
  opacity: 0.9;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.link {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}

.link:hover {
  color: var(--clr-blue);
}

.link svg {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.link:hover svg {
  transform: translateY(-2px);
}

.bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1.5rem;
  text-align: center;
}

.copyright {
  font-size: 0.9rem;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .content {
    grid-template-columns: 1fr;
  }
  
  .section {
    text-align: center;
  }
  
  .heading::after {
    left: 50%;
    transform: translateX(-50%);
  }
}
